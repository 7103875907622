<script lang="ts" setup>
import { useTopnav } from "~/layers/tairo-layout-topnav/composables/topnav"

const { isMobileOpen } = useTopnav()
</script>

<template>
  <TairoTopnavLayout
    display="condensed"
    main-class-display="md:pt-0 -pt-12"
    class="pt-16"
  >
    <template #navigation>
      <TairoTopnavNavigation display="expanded-lg" position="fixed">
        <div class="flex h-16 w-full items-center gap-x-4">
          <Logo height-class="h-12" />
          <div class="flex items-center justify-center md:hidden">
            <button type="button" @click="isMobileOpen = true">
              <Icon name="lucide:menu" class="text-muted-400 size-6" />
            </button>
          </div>
        </div>
        <template #toolbar>
          <div class="flex justify-end items-center gap-4">
            <ThemeToggle />
            <ButtonLogin />
          </div>
        </template>
      </TairoTopnavNavigation>
    </template>
    <NuxtPage />
  </TairoTopnavLayout>
</template>

<style scoped></style>
